.containerChat {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 2rem;
    gap: 1rem;
    flex: 4
}

.state {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 18px;
}

.strongStrong {
    margin-left: 5px
}

.chatItemMe {
    display: flex;
    justify-content: flex-end;
}
.chatItemOther {
    display: flex;
    justify-content: flex-start;
}

.chatItemText {
    background: #74d414;
    padding: 1rem;
    border-radius: 20px;
    font-size: 16px;
}
