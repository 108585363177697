.invisibility {
    visibility: hidden !important;
}

.loader-container {
    display: flex;
    align-items: center;
}

.loader-container span {
    margin-left: 15px;
}

.loader {
    width: 30px;
    height: 30px;
}

.loader > div {
    height: 100%;
    width: 8px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
}

.loader .bar1 {
    background-color: #74d414;
}

.loader .bar2 {
    background-color: #74d414;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.loader .bar3 {
    background-color: #74d414;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.loader .bar4 {
    background-color: #74d414;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.loader .bar5 {
    background-color: #74d414;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.loader .bar6 {
    background-color: #74d414;
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

@-webkit-keyframes delay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.05);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes delay {
    0%, 40%, 100% {
        transform: scaleY(0.05);
        -webkit-transform: scaleY(0.05);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    max-width: 500px;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px
}

.dropzone .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 200px;
    padding: 4px;
    box-sizing: border-box
}

.dropzone .thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden
}

.dropzone .thumb-inner img {
    display: block;
    width: auto;
    height: 100%
}



.container-recognize {
    display: block;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin: 0 0 20px;
}

.container-recognize .item {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.container-recognize .item a {
    text-decoration: none;
    cursor: pointer;
    color: #3F4353;
    font-size: 16px;
}

.recognize-result {
    padding: 10px;
}

.recognize-result-loader{
    display: flex;
    justify-content: center;
}

.recognize-result-title{
    color: #3F4353;
    font-size: 16px;
}

@media (min-width: 768px) {
    .container-recognize {
        display: flex;
    }
}
